import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    datePeriod: { value: '', label: '', en_id: '', success_filing_id: '', isDataAvailable: false, filing_type: '' },
    periodDataList: [],
    cmpHistoryList: [],
    yearPeriod: '',
    gstr1ReportDatePeriod: { startDate: '', endDate: '' },
    isDirty: false,
    previousDateperiod: { value: '', label: '', en_id: '', success_filing_id: '', isDataAvailable: false, filing_type: '' },
    previousYearPeriod: ''
}

export const gstr1DataSlice = createSlice({
    name: 'headerAction',
    initialState,
    reducers: {
        setDatePeriod: (state, action) => {
            state.datePeriod = action.payload
        },
        setPeriodDataList: (state, action) => {
            state.periodDataList = action.payload
        },
        setCmpHistoryList: (state, action) => {
            state.cmpHistoryList = action.payload
        },
        setYearPeriod: (state, action) => {
            state.yearPeriod = action.payload
        },
        setGstr1ReportPeriod: (state, action) => {
            state.gstr1ReportDatePeriod = action.payload
        },
        setIsDirty: (state, action) => {
            state.isDirty = action.payload
        },
        setPreviousDateperiod: (state, action) => {
            state.previousDateperiod = action.payload
        },
        setPreviousYearPeriod: (state, action) => {
            state.previousYearPeriod = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setDatePeriod, setPeriodDataList, setCmpHistoryList, setYearPeriod, setGstr1ReportPeriod, setIsDirty, setPreviousDateperiod, setPreviousYearPeriod } = gstr1DataSlice.actions

export default gstr1DataSlice.reducer
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    outward: { tab3_1: { system: [] }, tab3_1_1: { system: [] }, tab3_2: { system: [] } },
    eligible: { tab_4: { system: [] } },
    inward: { tab_5: { system: [] } },
    payment: { tab_6_1: { system: [] } },
    isGenerateChallan: false,
    isFillingStatus: false,
    saveStatus: true,
    ischangeData: false,
    availableData: false,
    isPreparedataChange: false
}

export const gstr3bDataSlice = createSlice({
    name: 'headerAction',
    initialState,
    reducers: {
        setOutwardDataList: (state, action) => {
            state.outward = action.payload
        },
        setEligibleDataList: (state, action) => {
            state.eligible = action.payload
        },
        setInwardDataList: (state, action) => {
            state.inward = action.payload
        },
        setPaymentDataList: (state, action) => {
            state.payment = action.payload
        },
        setIsGenereateChallan: (state, action) => {
            state.isGenerateChallan = action.payload
        },
        setSaveStatus: (state, action) => {
            state.saveStatus = action.payload
        },
        setDataEditStatus: (state, action) => {
            state.ischangeData = action.payload
        },
        setFillingStatus: (state, action) => {
            state.isFillingStatus = action.payload
        },
        setAvailableDataStatus: (state, action) => {
            state.availableData = action.payload
        },
        setIsPreparedataChange: (state, action) => {
            state.isPreparedataChange = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setOutwardDataList, setEligibleDataList, setInwardDataList, setPaymentDataList, setIsGenereateChallan, setSaveStatus, setDataEditStatus, setFillingStatus, setAvailableDataStatus, setIsPreparedataChange } = gstr3bDataSlice.actions

export default gstr3bDataSlice.reducer
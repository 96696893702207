import React, { Component } from 'react'
import { getCookie, setCookie } from '../helper/commonFunction'

class Errorboundry extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hasError: false
        }
    }

    componentDidCatch(error, info) {
        if (error && !this.state.hasError) {
            this.setState({
                hasError: true
            })
        }
    }

    render() {
        if (this.state.hasError) {
            if (window.location.host === 'gst.themunim.com' && !getCookie('is_error')) {
                setCookie('is_error', 1, 60)
                window.location.reload()
            } else if (window.location.host !== 'gst.themunim.com') {
                window.location.reload()
            }
            return
        }
        return this.props.children
    }
}

export default Errorboundry


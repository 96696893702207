// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
// import { munimApiEndpoint } from '../helper/commonApi'
// import axios from 'axios'

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}
// const handlelogout = async (user_id) => {
//   try {
//     const header = { 'refresh-token': localStorage.getItem('refresh_tokens') }
//     const finalObj = {
//       userId: user_id
//     }
//     const response = await axios.post(`${munimApiEndpoint}/log-out`, finalObj, { headers: header })
//     return response
//   } catch (error) {

//   }
// }

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      // state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      //  state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      //  localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      //  localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: state => {
      //  async (state, action) => {
      //   await handlelogout(action.payload)
      state.userData = {}
      localStorage.removeItem('refresh_tokens')

    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer

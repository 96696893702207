import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { munimApiEndpoint } from '../helper/commonApi'
import axios from 'axios'

const initialState = {
  user_name: '',
  is_pass_available: '',
  support_token: '',
  fname: '',
  lname: '',
  user_reg_date: '',
  user_id: '',
  email: '',
  user_rating: '',
  mobile: '',
  user_pan_number: '',
  selected_date_period: '',
  is_logout_loader: false,
  company_list: '',
  is_profile_complete: false,
  profile_status: '',
  incomplete_user_profile: '',
  user_created: '',
  popup_today_date: '',
  today_date: '',
  is_app_comp_exist: "0",
  user_last_login: '',
  user_verification_error: {
    error: '',
    message: ''
  }
}
export const handleLogout = createAsyncThunk('header/logout', async (user_id) => {
  try {
    const header = { 'refresh-token': localStorage.getItem('refresh_tokens') }
    const finalObj = {
      userId: user_id
    }
    const response = await axios.post(`${munimApiEndpoint}/log-out`, finalObj, { headers: header })
    return response
  } catch (error) {

  }
})

export const commonSlice = createSlice({
  name: 'demoSlice',
  initialState,
  reducers: {
    setUserName: (state, action) => {
      state.user_name = `${action.payload.fname} ${action.payload.lname}`
      state.fname = action.payload.fname
      state.lname = action.payload.lname
      state.user_reg_date = action.payload.reg_date
      state.is_pass_available = action.payload.is_pass_available
      state.user_id = action.payload.user_id
      state.email = action.payload.email
      state.mobile = action.payload.mobile
      state.support_token = action.payload.support_token
      state.user_rating = action.payload.rating
      state.user_created = action.payload.user_created
      state.incomplete_user_profile = action.payload.incomplete_user_profile
      state.profile_status = action.payload.profile_status
      state.today_date = action.payload.today_date
      const popup_today_date_time = new Date(action.payload.today_date)
      popup_today_date_time.setHours(0, 0, 0, 0)
      state.popup_today_date = popup_today_date_time
      state.is_app_comp_exist = action.payload.is_app_comp_exist
    },
    setPanNumber: (state, action) => {
      state.user_pan_number = action.payload
    },
    setSupportToken: (state, action) => {
      state.support_token = action.payload
    },
    setSelectedDatePeriod: (state, action) => {
      state.selected_date_period = action.payload
    },
    setUserEmail: (state, action) => {
      state.email = action.payload
    },
    setLogoutLoader: (state, action) => {
      state.is_logout_loader = action.payload
    },
    setCompanyList: (state, action) => {
      state.company_list = action.payload
    },
    setCompleteProfile: (state, action) => {
      state.is_profile_complete = action.payload
    },
    setUserVerificationError: (state, action) => {
      state.user_verification_error = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(handleLogout.fulfilled, (state) => {
        state.logout = true
      })
  }
})

// Action creators are generated for each case reducer function
export const { setUserName, setIsAdmin, setSupportToken, setPanNumber, setSelectedDatePeriod, setLName, setFName, setLogoutLoader, setUserEmail, setCompleteProfile, setCompanyList, setUserVerificationError } = commonSlice.actions

export default commonSlice.reducer